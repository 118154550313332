<template>
  <div class="flex flex-col gap-4 w-max" v-if="optionKey && is_edit">
    <div
        class="rounded-23px uppercase duration-400 font-semibold flex justify-center items-center"
        :class="[isClassOption, padding]"
        v-if="optionKey"
    >
      <v-text tag="span" :default_value="title" :option_key="optionKey"/>
    </div>
    <div
        class="rounded-23px uppercase duration-400 font-semibold flex justify-center items-center"
        :class="[isClassOption, padding]"
        v-else
    >
      {{ title }}
    </div>
    <div
        class="text-bw-16 placeholder-text-bw-16 rounded-md text-lg text-black border-bw-08 border-1 outline-none bg-white"
        v-if="optionValue"
    >
      <v-text tag="span" class="text-center" :default_value="$t('ENTER_URL')" :option_key="optionValue"/>
    </div>
  </div>
  <component
      v-else-if="optionKey && !is_edit"
      :is="isTypeComponent()"
      class="rounded-23px uppercase duration-400 font-semibold flex justify-center items-center cursor-pointer text-center w-full"
      :class="[isClassOption, urlButton ? '' : padding]"
      @click="handleClickTracking"
      role="button"
      tabindex="0"
  >
    <template v-if="urlButton">
      <template v-if="isType() == 'a'">
        <a :class="[padding]" target="_blank" :href="urlButton || '/'">
          <v-text tag="span" :default_value="title" :option_key="optionKey"/>
        </a>
      </template>
      <template v-else>
        <NuxtLink :to="urlButton || '/'" :class="[padding]">
          <v-text tag="span" :default_value="title" :option_key="optionKey"/>
        </NuxtLink>
      </template>
    </template>
    <template v-else>
      <v-text tag="span" :default_value="title" :option_key="optionKey"/>
    </template>
  </component>
  <component
      v-else
      :is="isTypeComponent()"
      class="rounded-23px uppercase duration-400 font-semibold flex justify-center items-center cursor-pointer text-center w-full"
      :class="[isClassOption, urlButton ? '' : padding]"
      @click="handleClickTracking"
      role="button"
      tabindex="0"
  >
    <template v-if="urlButton">
      <template v-if="isType() == 'a'">
        <a :class="[padding]" target="_blank" :href="urlButton || '/'">
          {{ title }}
        </a>
      </template>
      <template v-else>
        <NuxtLink :to="urlButton || '/'" :class="[padding]">
          {{ title }}
        </NuxtLink>
      </template>
    </template>
    <template v-else>
      <span>{{ title }}</span>
    </template>
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  url: {
    type: String
  },
  option: {
    type: Boolean
  },
  icon: {
    type: Boolean
  },
  padding: {
    type: String,
    default: 'px-8 py-3'
  },
  optionKey: {
    type: String,
    default: ''
  },
  optionValue: {
    type: String
  },
  clickTrackingHandler: {
    type: Function
  }
})
const is_edit = inject('is_edit')
const current_name_page = computed(() => (useRoute()?.name as string)?.split('___')?.[0] ?? 'global')
const urlButton = ref(
    props.url
        ? props.url
        : getDataGlobalByKey(useRoute().params.langCode, 'TEXT__' + props.optionValue, current_name_page.value)
            ?.option_value
)
const isType = () => {
  if (
      (urlButton.value && urlButton.value.includes('http')) ||
      urlButton.value.includes('tel:') ||
      urlButton.value.includes('mailto:')
  )
    return 'a'

  return resolveComponent('NuxtLink')
}

const isTypeComponent = () => {
  if (urlButton.value) return 'div'
  return 'button'
}

const isClassOption = computed(() => {
  if (props.option) {
    return 'block w-max text-accent-01 leading-5 text-sm rounded-full bg-white btn-effect-light'
  } else {
    return '!bg-primary text-bw-01 btn-effect text-sm'
  }
})

const handleClickTracking = (event: Event) => {
  if (props.clickTrackingHandler) {
    props.clickTrackingHandler(event);
  }
}
</script>

<style></style>
